// import Vue from "vue";
import { createStore } from "vuex";
// import Vuex from "vuex";
import modern from "./modules/modern";
import axios from "axios";

import cookies from "js-cookie";

/*Axios*/
window.axios = axios;

// Vue.use(Vuex);

export default createStore({
  state: {
    token: null,
    loggedInUserDetails: null,
    userIsLoggedIn: false,
  },
  getters: {
    token: (state) => state.token,
    loggedInUserDetails: (state) => state.loggedInUserDetails,
    userIsLoggedIn: (state) => state.userIsLoggedIn,
  },
  mutations: {
    SET_IS_USER_LOGGEDIN(state, loggedIn) {
      state.userIsLoggedIn = loggedIn;
    },
    SET_LOGGEDIN_USER_DETAILS(state, user_details) {
      state.loggedInUserDetails = user_details;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    REMOVE_TOKEN(state) {
      state.token = null;
    },
  },
  actions: {
    checkIsUserLoggedIn({ commit }) {
      let token = cookies.get("x-access-token");
      if (token) {
        commit("SET_IS_USER_LOGGEDIN", true);
      } else {
        commit("SET_IS_USER_LOGGEDIN", false);
      }
    },
    setToken({ commit }, { access_token, expires_at }) {
      const expiryTime = new Date(new Date().getTime() + expires_at * 1000);
      cookies.set("x-access-token", access_token, { expires: expiryTime });
      commit("SET_TOKEN", access_token);
      commit("SET_IS_USER_LOGGEDIN", true);
    },
    logout({ commit }) {
      cookies.remove("x-access-token");
      commit("SET_IS_USER_LOGGEDIN", false);
      commit("REMOVE_TOKEN");
    },
    getLoggedInUserDetails({ commit }) {
      let accessToken = cookies.get("x-access-token");

      if (!accessToken || accessToken == null) {
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get("init", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(
            (response) => {
              commit("SET_LOGGEDIN_USER_DETAILS", response.auth_user);
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
  },
  modules: {
    modern,
  },
});
