// import Vue from "vue";
// import VueRouter from "vue-router";
import { createWebHistory, createRouter } from "vue-router";

//import Home from '../views/Home.vue'
const Home = () => import("../views/Home.vue");
//import About from '../views/About.vue'
const About = () => import("../views/About.vue");
//import Contact from '../views/Contact.vue'
const Contact = () => import("../views/Contact.vue");
//import Buses from '../views/Buses.vue'
const Buses = () => import("../views/Buses.vue");
//import Services from '../views/Services.vue'
const Services = () => import("../views/Services.vue");
//import Faqs from '../views/Faqs.vue'
const Faqs = () => import("../views/Faqs.vue");
//Portal
//import Portal from '../views/Portal.vue'
const Portal = () => import("../views/Portal.vue");
//import Careers from '../views/Careers.vue'
const Careers = () => import("../views/Careers.vue");
//Passanger Form Page
//import Passangers from '../components/AppPassangerForm.vue'
const Passangers = () => import("../components/AppPassangerForm.vue");
//Return Bus Results
//import ReturnBus from '../components/AppReturnBusResults.vue'
const ReturnBus = () => import("../components/AppReturnBusResults.vue");
//Bus seats
//import BusSeats from '../components/AppBusSeats.vue'
const BusSeats = () => import("../components/AppBusSeats.vue");
//Return Bus seats
//import ReturnBusSeats from '../components/AppReturnBusSeats.vue'
const ReturnBusSeats = () => import("../components/AppReturnBusSeats.vue");
//Payment
//import Payment from '../components/AppPayment.vue'
const Payment = () => import("../components/AppPayment.vue");
//Return Bus seats
//import TripReview from '../components/AppTripReview.vue'
const TripReview = () => import("../components/AppTripReview.vue");

//T & Cs
const T_C = () => import("../views/T_And_C.vue");

//Privacy
const Privacy = () => import("../views/Privacy.vue");

// Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/buses",
    name: "Buses",
    component: Buses,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
  },
  {
    path: "/portal",
    name: "Portal",
    component: Portal,
  },
  {
    path: "/Passangers",
    name: "Passangers",
    component: Passangers,
  },
  {
    path: "/BusSeats",
    name: "BusSeats",
    component: BusSeats,
  },
  {
    path: "/ReturnBusSeats",
    name: "ReturnBusSeats",
    component: ReturnBusSeats,
  },
  {
    path: "/ReturnBus",
    name: "ReturnBus",
    component: ReturnBus,
  },
  {
    path: "/TripReview",
    name: "TripReview",
    component: TripReview,
  },
  {
    path: "/Payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/t&cs",
    name: "Terms_Conditions",
    component: T_C,
  },
  {
    path: "/privacy",
    name: "Privacy_Policy",
    component: Privacy,
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// const router = new VueRouter({
//   routes,
// });

export default router;
