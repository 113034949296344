//Router
import router from "../../router";

//Axios
import axios from "axios";
//Set the base URL for the APIs
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers.common["Accept"] = "application/json";

//Moment js
import moment from "moment";

import cookies from "js-cookie";

//Lodash
import _ from "lodash";

//Izi Toast
import izitoast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
import "izitoast/dist/js/iziToast.min.js";

//Default data State
const getDefaultState = () => {
  return {
    //Loader status
    show_Loader: true,
    //Object for all towns, this is updated after the API request
    towns: [
      { id: 1, town_name: "Nairobi" },
      { id: 2, town_name: "Mombasa" },
    ],
    //Object for trip details
    trip: {
      town_from: "",
      town_to: "",
      town_from_return: "",
      town_to_return: "",
      travelling_on: new Date(),
      returning_on: "",
      boarding_point: "",
      dropping_point: "",
      returning_boarding_point: "",
      returning_dropping_point: "",
      selected_bus: [],
      return_selected_bus: [],
    },
    //All Bus Seats object
    bus_seats: {},
    //All Return Bus Seats object
    return_bus_seats: {},
    //Town Scedule object
    town_schedules: {},
    //Return Town Scedule object
    return_town_schedules: {},
    //Selected Seats Array
    selected_seats: [],
    //Return Selected Seats Array
    return_selected_seats: [],
    //Total Amount
    total_amount: 0,
    //Return Total Amount
    return_total_amount: 0,
    //Passanger Details
    passenger_details: [],
    //Trip info to be submitted
    trip_info: {},
    //Active Returning Pssanger
    active_returning_passanger_index: "",
    //Active Returning Seat
    active_returning_seat: "",
    //Status of seats locking
    seats_locked: false,
    //Payment status, 0 = STK Not Pushed, 1 = STK Pushed, 2 = Successfully Paid, 3 = Not Successful
    payment_status: 0,
    //Show Modal
    showModal: false,
    //Booked status
    ticket_booked: false,
    ticket_not_booked: false,

    // total discount for all code
    total_code_discount: 0,
  };
};

//state
const state = getDefaultState();

//getters
const getters = {
  //All Towns Object
  all_towns: (state) => state.towns,
  //Trip details (to, from and travelling on)
  trip: (state) => state.trip,
  //Town Schedules, including buses array
  town_schedules: (state) => state.town_schedules,
  //Town Schedules, including buses array
  return_town_schedules: (state) => state.return_town_schedules,
  //Bus Seats Details (Creates the bus seats Template)
  bus_seats: (state) => state.bus_seats,
  //Bus Seats Details (Creates the bus seats Template)
  return_bus_seats: (state) => state.return_bus_seats,
  //Object for Selected Seat
  selected_seats: (state) => state.selected_seats,
  //Object for Selected Seat
  return_selected_seats: (state) => state.return_selected_seats,
  //Total Amount
  total_amount: (state) => state.total_amount,
  //Return Total Amount
  return_total_amount: (state) => state.return_total_amount,
  //Passanger Details
  passenger_details: (state) => state.passenger_details,
  //Trip info to be submitted
  trip_info: (state) => state.trip_info,
  //Loader action
  show_Loader: (state) => state.show_Loader,
  //Status of locking seats
  seats_locked: (state) => state.seats_locked,
  //Payment Status
  payment_status: (state) => state.payment_status,
  //Show Modal
  showModal: (state) => state.showModal,
  //Get active retturning index
  active_returning_passanger_index: (state) =>
    state.active_returning_passanger_index,
  //Active Returning seat
  active_returning_seat: (state) => state.active_returning_seat,

  total_code_discount: (state) => state.total_code_discount,

  /* validate phone */
  validatePhone: (state) => ($thimo) => {
    console.log(state.show_Loader);
    /* +254 7xx xxx xxx, 254 7xx xxx xxx, 07xx xxx xxx */
    if (
      /^(?:7)?((?:(?:[123456789][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/.test(
        $thimo
      )
    ) {
      //Matches 7
      return $thimo;
    } else if (
      /^(?:0)?(7(?:(?:[123456789][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/.test(
        $thimo
      )
    ) {
      //Matches 07
      return $thimo.substring(1);
    } else if (
      /^(?:254)?(7(?:(?:[123456789][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/.test(
        $thimo
      )
    ) {
      //Matches 254
      return $thimo.substring(3);
    } else if (
      /^(?:\+254)?(7(?:(?:[123456789][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/.test(
        $thimo
      )
    ) {
      //Matches +254
      return $thimo.substring(4);
    } else {
      return 0;
    }
  },
};

//actions
const actions = {
  //Reset State
  resetModernState({ commit }) {
    commit("resetState");
  },
  //Show Loader
  showLoader({ commit }) {
    commit("showLoaderAction", true);
  },
  //Show Modal
  showTheModal({ commit }) {
    //show modal componenet
    commit("showModalAction", true);
  },
  //Hide Loader
  hideLoader({ commit }) {
    commit("hideLoaderAction", false);
  },
  //Hide Modal
  hideModal({ commit }) {
    //show modal componenet
    commit("hideModalAction", false);
  },
  //Warning alert
  /* eslint-disable */
  showWarning({ getters }, message) {
    //console.log(getters.seats_locked);
    //Alert
    izitoast.warning({
      // title: "Caution",
      message: message,
      class: "modern-toast",
      position: "topRight",
      titleColor: "#2f2d98",
      messageColor: "#000000",
      backgroundColor: "#ffffff",
      layout: 2,
    });
  },
  /* eslint-enable */
  //show error
  showError({ getters }, message) {
    console.log(getters.seats_locked);
    //Alert
    izitoast.error({
      // title: "Error",
      // titleLineHeight: "1.6px",
      message: message,
      class: "modern-toast",
      position: "topRight",
      titleColor: "#2f2d98",
      messageColor: "#000000",
      backgroundColor: "#ffffff",
      layout: 2,
    });
  },
  //show success
  showSuccess({ getters }, message) {
    console.log(getters.seats_locked);
    //Alert
    izitoast.success({
      // title: "Success",
      message: message,
      class: "modern-toast",
      position: "topRight",
      titleColor: "#2f2d98",
      messageColor: "#000000",
      backgroundColor: "#ffffff",
      layout: 2,
    });
  },
  //Modal Event
  modalClosedEvent({ dispatch }) {
    //hide modal componenet
    dispatch("hideModal");

    //show passanger form componenet
    router.push("Passangers");
  },
  //Get active Returning index
  getActiveReturningIndex({ commit }, index) {
    commit("setActiveReturningIndex", index);
  },
  //Set Active Returning Seat
  getActiveReturningSeat({ commit }, seat) {
    commit("setActiveReturningSeat", seat.seat);
  },
  //Fetch All Towns
  async fetchTowns({ commit, dispatch }) {
    const res = await axios.get("towns");

    commit("setTowns", res.data);

    //Hide Loader
    dispatch("hideLoader");
  },
  //Bus Selected
  busSeatSelectedEvent({ dispatch }, trip) {
    let boarding_point = trip.boarding_point;
    let dropping_point = trip.dropping_point;
    let selected_seats = trip.selected_seats;
    let is_return = trip.is_return;

    //Check if details have been filled
    if (boarding_point == "") {
      //Alert
      dispatch("showWarning", "Kindly fill the boarding point");
    } else if (dropping_point == "") {
      //Alert
      dispatch("showWarning", "Kindly fill the dropping point");
    } else if (selected_seats.length < 1) {
      //Alert
      dispatch("showWarning", "Kindly select a seat");
    } else {
      if (is_return) {
        //Lock Seats
        dispatch("getLockSeats", { seat_is_return: true });
      } else {
        //Lock Seats
        dispatch("getLockSeats", { seat_is_return: false });
      }
    }
  },
  //Set Pass Details
  getReturningTicket({ commit, dispatch, getters }, is_return) {
    if (is_return) {
      //Set Return details
      let active_pass =
        getters.passenger_details[getters.active_returning_passanger_index];

      active_pass.return_ticket = {
        bus_type_id: getters.trip.return_selected_bus.route_id,
        ticket_seat_id: getters.active_returning_seat.id,
        ticket_seat_amount:
          getters.active_returning_seat.price.price_kes_amount -
          parseInt(getters.return_bus_seats.online_discount.KES) -
          parseInt(getters.return_bus_seats.return_discount.KES),
        return_dropoff_branch: getters.trip.returning_dropping_point.branch_id,
        return_pickup_branch: getters.trip.returning_boarding_point.branch_id,
        ticket_town_to_id: getters.trip.town_to_return.id,
        ticket_schedule_id: getters.trip.return_selected_bus.schedule_id,
        ticket_town_from_id: getters.trip.town_from_return.id,
      };

      //Set pass return seat
      active_pass.return_seat = getters.active_returning_seat;

      //Update Passanger
      commit("setPassanger", {
        index: getters.active_returning_passanger_index,
        passanger: active_pass,
      });

      //show passanger form componenet
      router.push("Passangers");

      //Update Active Return Seat
      commit("setActiveReturningSeat", "");
    } else {
      //scroll to top
      window.scrollTo(0, 0);
      //Show Modal
      //do not show if user is loggedIn
      if (!cookies.get("x-access-token")) {
        dispatch("showTheModal");
      } else {
        //navigate to passanger view
        router.push("Passangers");
      }
    }
  },
  //Remove Returning Details
  removeReturningDetails({ commit, getters }, index) {
    //Set Return details
    let active_pass = getters.passenger_details[index];

    if (active_pass.return_ticket) {
      let updated_active_pass = _.omit(active_pass, "return_ticket");
      //Update Passanger
      commit("setPassanger", { index: index, passanger: updated_active_pass });
    }
  },
  //Submit trip details
  getSubRequest({ commit, dispatch }, trip) {
    //Show Loader
    dispatch("showLoader");

    if (!trip.is_return) {
      //Remove existing town schedule object
      commit("setNullTownSchedules");
    } else {
      //Remove existing return town schedule object
      commit("setNullReturnTownSchedules");
    }

    if (
      trip.town_from.town_name == undefined ||
      trip.town_to.town_name == undefined
    ) {
      //Hide Loader
      dispatch("hideLoader");

      //Alert
      dispatch("showWarning", "Kindly fill all the details");
    } else if (trip.town_from.town_name !== trip.town_to.town_name) {
      dispatch("getTownSchedules", {
        from: trip.town_from.id,
        to: trip.town_to.id,
        date_travelling: trip.travelling_on,
        is_return: trip.is_return,
      });
    } else {
      //Hide Loader
      dispatch("hideLoader");

      //Alert
      dispatch("showWarning", "Towns cannot be the same!");
    }
  },
  getReturningDetails({ commit, getters }) {
    let returning_on;

    //Check if returning is set
    if (getters.trip.returning_on) {
      returning_on = getters.trip.returning_on;
    } else {
      returning_on = getters.trip.travelling_on;
    }

    //Commit Returning Details
    commit("setReturningDetails", {
      from: getters.trip.town_from,
      to: getters.trip.town_to,
      returning_on: returning_on,
    });
  },
  //Mpesa STK Push
  getStkPush({ commit, getters, dispatch }) {
    console.log(
      "Invalid Phone",
      getters.passenger_details[0].passenger_phone_number
    );
    let to_match = /^(?:254|\+254|0)?(7(?:(?:[0-9][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/;
    let check_validity = to_match.test(
      getters.passenger_details[0].passenger_phone_number
    );
    if (!check_validity) {
      dispatch("showWarning", "The Phone Number is invalid!");
      return;
    }

    //Show Loader
    dispatch("showLoader");
    //let totalAmount;
    let totalAmount = getters.total_amount + getters.return_total_amount;

    //Data
    let stk_data = {
      ticket_currency_id: 1,
      customer_phone_number:
        getters.passenger_details[0].passenger_phone_number,
      ticket_channel_id: 3,
      customer_phone_code: getters.passenger_details[0].passenger_phone_code,
      payment_unique_number: getters.trip_info.payment.payment_unique_number,
      ticket_amount: totalAmount - getters.total_code_discount,
    };

    //API request for STK Push
    axios
      .post("push-mpesa", stk_data)
      .then((res) => {
        console.log(res);
        if (res.data.success == 1) {
          commit("paymentStatusAction", 1);

          //alert('Checking payments');
          console.log("Checking payment");

          dispatch("getPaymentStatus");
        } else {
          commit("paymentStatusAction", 0);

          //Alert
          dispatch("showError", "Sorry, could not complete request");

          //Hide Loader
          dispatch("hideLoader");
        }
      })
      .catch((err) => {
        console.log(err);
        commit(" paymentStatusAction", 0);

        //Alert
        dispatch("showError", "Sorry, could not complete request");

        //Hide Loader
        dispatch("hideLoader");
      });
  },

  //API Check Payment Status
  async getPaymentStatus({ getters, commit, dispatch }) {
    //Show Loader
    dispatch("showLoader");

    var counter = 0;
    var paymentDelay = setInterval(function() {
      console.log("Counter", counter);

      axios
        .get(
          `/search-mpesa?payment_unique_number=${getters.trip_info.payment.payment_unique_number}&customer_phone_number=${getters.passenger_details[0].passenger_phone_number}&customer_phone_code=${getters.passenger_details[0].passenger_phone_code}`
        )
        .then((response) => {
          if (response.data.mpesa_transaction_no) {
            dispatch("showSuccess", "Transaction has been received!");
            commit("paymentStatusAction", 2);
            dispatch("hideLoader");

            clearInterval(paymentDelay);
          }

          if (!response.data.mpesa_transaction_no && counter == 5) {
            dispatch("showError", "Sorry, could not confirm transaction");
            commit("paymentStatusAction", 3);
            dispatch("hideLoader");

            clearInterval(paymentDelay);
          }
        })
        .catch((err) => {
          console.log("Error", err);

          dispatch("showError", "Sorry, could not confirm transaction");

          commit("paymentStatusAction", 3);

          dispatch("hideLoader");

          clearInterval(paymentDelay);
        });

      counter++;
    }, 10000);
  },
  busSelectedEvent({ dispatch }, bus) {
    //Update Schedule Id, Boarding & Picking points
    dispatch("vehicleSelected", { bus: bus.bus, is_return: bus.is_return });

    //scroll to top
    window.scrollTo(0, 0);

    if (!bus.is_return) {
      //Redirect to Bus seats page
      router.push("BusSeats");
    } else {
      //Redirect to Return Bus seats page
      router.push("ReturnBusSeats");
    }
  },
  //Type of Bus Selected
  vehicleSelected({ commit }, bus) {
    if (!bus.is_return) {
      //Commit Selected Bus
      commit("setSelectedBus", bus.bus);
    } else {
      //Commit Return Selected Bus
      commit("setReturnSelectedBus", bus.bus);
    }
  },
  //Get Bus Seats Object
  getBusSeats({ commit }, bus_seats) {
    if (!bus_seats.is_return) {
      //Commit Bus Seats
      commit("setBusSeats", bus_seats.seats);
    } else {
      //Commit Return Bus Seats
      commit("setReturnBusSeats", bus_seats.seats);
    }
  },
  //Get Selected Seats
  getSelectedSeats({ commit }, seat) {
    if (!seat.is_return) {
      //Commit Selected Seat
      commit("setSelectedSeat", seat.seat);
    } else {
      //Commit Return Selected Seat
      commit("setReturnSelectedSeat", seat.seat);
    }
  },
  //Remove UnSelected Seats
  removeUnSelectedSeats({ commit }, seat) {
    if (!seat.is_return) {
      //Remove Selected Seat
      commit("removeUnSelectedSeatAction", seat.seat);
    } else {
      //Remove Selected Seat
      commit("removeReturnUnSelectedSeatAction", seat.seat);
    }
  },
  //Get Total Amount
  getTotalAmount({ commit, getters, dispatch }, status) {
    let total = 0;

    if (!status.is_return) {
      getters.selected_seats.forEach((seat) => {
        total +=
          seat.price.price_kes_amount -
          parseInt(getters.bus_seats.online_discount.KES);
      });
      //Update passanger Array
      dispatch("getPassangerArray");
      //Set Total Amount
      commit("setTotalAmount", total);
    } else {
      getters.return_selected_seats.forEach((seat) => {
        total +=
          seat.price.price_kes_amount -
          parseInt(getters.return_bus_seats.return_discount.KES) -
          parseInt(getters.bus_seats.online_discount.KES);
      });

      //Set Total Amount
      commit("setReturnTotalAmount", total);
    }
  },
  //Update Passanger Array
  getPassangerArray({ commit, getters }) {
    let passanger_array = [];
    getters.selected_seats.forEach((seat) => {
      let passanger_object = {
        passenger_nationality_id: "",
        passenger_id_number: "",
        passenger_phone_code: "",
        passenger_gender: "",
        ticket_seat_id: "",
        passenger_email: "",
        book_return: 0,
        passenger_phone_number: "",
        email_ticket: "",
        passenger_name: "",
        return_seat: "",
      };

      passanger_object.email_ticket = 0;
      passanger_object.ticket_seat_id = parseInt(seat.id);
      passanger_array.push(passanger_object);
    });
    //Commit Passanger Array
    commit("setPassangerArray", passanger_array);
  },
  //Lock Seats
  async getLockSeats({ commit, getters, dispatch }, seat) {
    //Show Loader
    dispatch("showLoader");

    //Booked Seats
    let booked_seats = [];
    let lock_seat_data;

    if (!seat.seat_is_return) {
      getters.selected_seats.forEach((seat) => {
        booked_seats.push({ seat_id: seat.id });
      });

      //Lock Seat Data
      lock_seat_data = {
        booked_seat_schedule_id: getters.trip.selected_bus.schedule_id,
        booked_seat_town_from_id: getters.trip.town_from.id,
        booked_seat_town_to_id: getters.trip.town_to.id,
        booked_seats: booked_seats,
        booked_seat_session_id: getters.bus_seats.booked_seat_session_id,
      };
    } else {
      getters.return_selected_seats.forEach((seat) => {
        booked_seats.push({ seat_id: seat.id });
      });
      //booked_seats.push({ seat_id: seat.seat.id });

      //Lock Seat Data
      lock_seat_data = {
        booked_seat_schedule_id: getters.trip.return_selected_bus.schedule_id,
        booked_seat_town_from_id: getters.trip.town_from_return.id,
        booked_seat_town_to_id: getters.trip.town_to_return.id,
        booked_seats: booked_seats,
        booked_seat_session_id: getters.bus_seats.booked_seat_session_id,
      };
    }

    //API request to lock seat
    let res = await axios.post("lock-seat", lock_seat_data).catch((err) => {
      //Hide Loader
      dispatch("hideLoader");

      commit("lockSeatAction", false);

      console.log(err.response.data);

      //Alert
      dispatch(
        "showError",
        `Sorry, ${err.response.data.message}. Kindly select other seats.`
      );

      //Remove seat from array
      //dispatch('removeUnSelectedSeats', { seat: seat.seat, is_return: seat.seat_is_return });
      //Recalculate Amount
      //dispatch('getTotalAmount', { is_return: seat.seat_is_return });

      return false;
    });

    if (res?.data?.success == 1) {
      commit("lockSeatAction", true);

      //Hide Loader
      dispatch("hideLoader");

      //Alert
      //dispatch("showSuccess", "Seat successfully locked!");

      dispatch("getReturningTicket", seat.seat_is_return);
    } else {
      console.log(res.data.message);
      //Hide Loader
      dispatch("hideLoader");

      commit("lockSeatAction", false);
      //Alert
      dispatch(
        "showError",
        "Sorry, could not lock the seats at this time. Kindly select other seats."
      );

      //Remove seat from array
      //dispatch('removeUnSelectedSeats', { seat: seat.seat, is_return: seat.seat_is_return });
      //Recalculate Amount
      //dispatch('getTotalAmount', { is_return: seat.seat_is_return });
    }
  },
  //Get Town Schedules
  getTownSchedules({ commit, dispatch }, details) {
    let travel_date = moment(details.date_travelling).format("YYYY-MM-DD");
    let town_from_id = details.from;
    let town_to_id = details.to;
    let is_return = details.is_return;

    //API request to get town schedules
    axios
      .get(
        `/town-schedules?travel_date=${travel_date}&town_from_id=${town_from_id}&town_to_id=${town_to_id}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          if (!is_return) {
            //Commit town Schedules
            commit("setTownSchedules", res.data);
          } else {
            //Commit town Schedules
            commit("setReturnTownSchedules", res.data);
          }

          //Hide Loader
          dispatch("hideLoader");

          if (!is_return) {
            //Action to go to buses schedule page
            router
              .push({ name: "Buses", params: { userId: "123" } })
              .catch((error) => {
                if (error.name != "NavigationDuplicated") {
                  throw error;
                }
              });
          }
        } else {
          //Hide Loader
          dispatch("hideLoader");

          //Alert
          dispatch(
            "showWarning",
            "Sorry, there are no scheduled buses at the moment."
          );
        }
      })
      .catch((err) => {
        //Hide Loader
        dispatch("hideLoader");

        //Alert
        dispatch("showError", "Sorry, could not complete the request");

        console.log(`Error : ${err}`);
      });
  },
  //Trip info to be submitted
  getTripInfo({ commit, getters }, discount) {
    console.log("discount:", discount);
    commit("setTotalCodeDiscount", discount);

    //let return_ticket;

    //Update Passangers Phone Number
    getters.passenger_details.forEach((passanger) => {
      passanger["passenger_phone_number"] = getters.validatePhone(
        passanger["passenger_phone_number"]
      );

      delete passanger.return_seat;
    });

    //Commit Passanger Array
    commit("setPassangerArray", getters.passenger_details);

    //Total
    //let totalAmount;
    let totalAmount = getters.total_amount + getters.return_total_amount - discount;

    //New Trip Details
    let trip_info = {
      ticket_dropoff_branch_id: getters.trip.dropping_point.branch_id,
      ticket_pickup_branch_id: getters.trip.boarding_point.branch_id,
      ticket_time_taken: 0,
      ticket_seats: getters.passenger_details,
      ticket_town_to_id: getters.trip.town_to.id,
      ticket_town_from_id: getters.trip.town_from.id,
      ticket_currency_id: 1,
      booked_seat_session_id: getters.bus_seats.booked_seat_session_id,
      ticket_channel_id: 3,
      payment: {
        pay_later: 1,
        payment_mode_id: 4,
        payment_unique_number: `ON${_.times(6, () => _.random(35).toString(36))
          .join("")
          .toUpperCase()}`,
      },
      ticket_amount: totalAmount,
      ticket_schedule_id: getters.trip.selected_bus.schedule_id,
      customer: {
        customer_phone_number:
          getters.passenger_details[0].passenger_phone_number,
        customer_phone_code: getters.passenger_details[0].passenger_phone_code,
        customer_name: getters.passenger_details[0].passenger_name,
      },
    };

    //console.log(JSON.stringify(trip_info, null, "\t"));

    //Commit Trip Info
    commit("setTripInfo", trip_info);
  },
};

//mutations
const mutations = {
  //Show loader
  showLoaderAction: (state, value) => (state.show_Loader = value),
  //Hide Loader
  hideLoaderAction: (state, value) => (state.show_Loader = value),
  //Sets all towns Array
  setTowns: (state, towns) => (state.towns = towns),
  //Sets Town Schedules
  setTownSchedules: (state, town_schedules) =>
    (state.town_schedules = town_schedules),
  //Sets Town Schedules
  setReturnTownSchedules: (state, town_schedules) =>
    (state.return_town_schedules = town_schedules),
  //Clears town schedules object
  setNullTownSchedules: (state) => (state.town_schedules = ""),
  //Clears return town schedules object
  setNullReturnTownSchedules: (state) => (state.return_town_schedules = ""),
  //Set Selected Bus
  setSelectedBus: (state, bus) => (state.trip.selected_bus = bus),
  //Set Return Selected Bus
  setReturnSelectedBus: (state, bus) => (state.trip.return_selected_bus = bus),
  //Sets all bus seats
  setBusSeats: (state, bus_seats) => (state.bus_seats = bus_seats),
  //Sets all bus seats
  setReturnBusSeats: (state, bus_seats) => (state.return_bus_seats = bus_seats),
  //Inserts Selected Seat to object
  setSelectedSeat: (state, selected_seat) =>
    state.selected_seats.push(selected_seat),
  //Inserts Selected Seat to object
  setReturnSelectedSeat: (state, selected_seat) =>
    state.return_selected_seats.push(selected_seat),
  //Removes unselected seat from selected seats object
  removeUnSelectedSeatAction(state, unselected_seat) {
    state.selected_seats = _.reject(state.selected_seats, unselected_seat);
  },
  //Removes unselected seat from selected seats object
  removeReturnUnSelectedSeatAction(state, unselected_seat) {
    state.return_selected_seats = _.reject(
      state.return_selected_seats,
      unselected_seat
    );
  },
  //Update Total Amount
  setTotalAmount: (state, total) => (state.total_amount = total),
  //Update Total Amount
  setReturnTotalAmount: (state, total) => (state.return_total_amount = total),
  //Update Passanger Array
  setPassangerArray: (state, passanger_array) =>
    (state.passenger_details = passanger_array),
  //Set Trip Info
  setTripInfo: (state, trip_info) => (state.trip_info = trip_info),
  //Lock Seat Action
  lockSeatAction: (state, lock_seat_status) =>
    (state.seats_locked = lock_seat_status),
  //Set payment_status
  paymentStatusAction: (state, the_payment_status) =>
    (state.payment_status = the_payment_status),
  //Show Modal
  showModalAction: (state, modal_show) => (state.showModal = modal_show),
  //hideModalAction
  hideModalAction: (state, modal_show) => (state.showModal = modal_show),
  //set active returning index
  setActiveReturningIndex: (state, index) =>
    (state.active_returning_passanger_index = index),
  //Update passanger
  setPassanger(state, details) {
    state.passenger_details[details.index] = details.passanger;
  },
  //Set Returning Details
  setReturningDetails(state, trip) {
    state.trip.town_from_return = trip.to;
    state.trip.town_to_return = trip.from;
    state.trip.returning_on = trip.returning_on;
  },
  //set Active Returning Seat
  setActiveReturningSeat: (state, seat) => (state.active_returning_seat = seat),
  //Reset State
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setTotalCodeDiscount: (state, value) => (state.total_code_discount = value),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
