<template>
  <div id="app" class="site-wrapper pos-relative">
    <router-view />
  </div>
</template>

<style lang="scss">
.m-invisible {
  opacity: 0;
  visibility: hidden;
}
</style>