// import Vue from "vue";
import { createApp } from "vue";

// import {
//   Pagination,
//   Table,
//   TableColumn,
//   DatePicker,
//   Loading,
//   Row,
//   Button,
// } from "element-ui";
// import lang from "element-ui/lib/locale/lang/en";
// import locale from "element-ui/lib/locale";

import ElementPlus from "element-plus";

import App from "./App.vue";

//Router
import router from "./router";
import VueGtag from "vue-gtag";
import store from "./store";
const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueGtag, {
  config: { id: "G-YK9XY7E77T" },
});

//Moment js
import moment from "moment";

//Numeral
import numeral from "numeral";

//Lodash
import _ from "lodash";
window._ = _;

//Axios
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = 'https://api.modern.co.ke/api/v2/';

app.config.globalProperties.$http = axios;
app.config.globalProperties.$store = store;

app.use(VueAxios, axios);
// app.prototype.$http = axios;

//Font Awesome
// import "@fortawesome/fontawesome-free/css/all.min.css"; DAN

//() => import('@fortawesome/fontawesome-free/css/fontawesome.min.css');
//import "@fortawesome/fontawesome-free/js/fontawesome.min.js";
//() => import('@fortawesome/fontawesome-free/js/fontawesome.min.js');

//Normalize
import "normalize.css";

import "vue-select/dist/vue-select.css"; //DAN

//Global Style
import "./styles/app.scss";

app.config.productionTip = false;

// configure language DAN
// locale.use(lang);

app.use(ElementPlus);
// Vue.use(Pagination);
// Vue.use(Table);
// Vue.use(TableColumn);
// Vue.use(DatePicker);
// Vue.use(Button);
// Vue.use(Row);
// Vue.use(Loading.directive);

// Vue.prototype.$loading = Loading.service; DAN

//Register Glocal DatePicker Component
// Vue.component("DatePicker", () =>
//   import("v-calendar/lib/components/date-picker.umd")
// ); DAN

import { Calendar } from "vue-calendar-3";
// If you not
// import "vue-calendar-3/dist/style.css";

export default {
  components: {
    Calendar,
  },
};

// import { VueSelect } from "vue-select";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

//Register Glocal Vue-Select Component
// Vue.component("vSelect", () => import("vue-select"));

app.component("v-select", vSelect);

app.config.globalProperties.$filters = {
  formatDate(date_) {
    return moment(date_).format("MMM Do YYYY");
  },
  formatCurrency(n) {
    var number = numeral(n);
    var string = number.format("0,0.00");
    return string;
  },
  uppercase(n) {
    return n.toUpperCase();
  },
};

//filters
//Format date in MMM Do YYYY Format
// Vue.filter("formatDate", function(date_) {
//   return moment(date_).format("MMM Do YYYY");
// });

//Format currency
// Vue.filter("formatCurrency", function(n) {
//   var number = numeral(n);
//   var string = number.format("0,0.00");
//   return string;
// });

// uppercase filter
// Vue.filter("uppercase", function(n) {
//   return n.toUpperCase();
// });

app.mount("#app");

// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");

//Vue.http.options.root = 'http://example.com';
